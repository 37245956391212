<template>
  <div class="row">
    <div
      class="rounded-md col"
    >
      <LMap ref="map"
            :options="mapOptions"
            :class="getListenMapLocationStatus ? 'cursor-crosshair' : 'cursor-grab'"
            style="height: 79vh"
            @ready="mapIsReady = true"
            v-model:zoom="zoom"
            @click="getMapClickLocation"
            :center="center"
      >
        <LTileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="OpenStreetMap.org"/>
        <!-- Incident center marker -->
        <LMarker
          v-if="getCurrentIncident"
          :lat-lng="[
            getCurrentIncident.coordinates.points.latitude,
            getCurrentIncident.coordinates.points.longitude
          ]"
          >
          <LIcon :icon-url="require(`@/assets/images/incident/${getCurrentIncident.map_icon}`)" />
        </LMarker>
        <LMarker
          v-for="(marker, index) in markers"
          :key="index"
          :lat-lng="[marker.lat, marker.lng]"
          :attribution="marker.id.toString()"
          @click="(event) => markerEditing(event, marker)"
        >
          <LIcon
            :icon-url="
            require(`@/assets/images/${this.getFormattedAssetUrl(marker.icon)}`)"
          />
        </LMarker>
        <LMarker
          v-for="(landmark, index) in getCurrentLandmarks"
          :key="index"
          :lat-lng="[landmark.latitude, landmark.longitude]"
          @click="(event) => landmarkEditing(event, landmark)"
        >
          <LIcon
            :icon-url="
            require(`@/assets/images/${this.getFormattedLandmarkAssetUrl(landmark.icon)}`)"
          />
        </LMarker>
        <LControl
          v-if="getCurrentIncident"
          position="bottomright"
        >
          <IncidentResource
            @mapFocus="updateMapCenter"
            @mouseOverWindow="disableMapScroll"
          />
        </LControl>
        <LControl
          v-if="getCurrentIncident"
          position="topleft"
        >
          <div class="d-flex flex-column justify-content-start">
            <Button
              tooltipPosition="right"
              customClasses="rounded-0 rounded-top rounded-right rounded-end"
              variant="secondary"
              :data="{ description: 'Command and Planning'}"
            >
              <template v-slot:content="data">
                <img src="@/assets/images/logo.svg" :alt="data.description" class="mx-2" />
              </template>
            </Button>
            <Button
              tooltipPosition="right"
              customClasses="rounded-0 rounded-bottom mt-n1 w-75 border-0 p-auto"
              @click="updateMapCenter({...getCurrentIncident.coordinates.points})"
              variant="secondary"
              :data="{
                description: 'Center on incident',
                icon: 'fas fa-arrows-to-circle',
              }"
            />
          </div>
        </LControl>
      </LMap>
    </div>
    <div class="col-md-4 col-xl-2 bg-light shadow-md" v-show="isEditing">
      <MapToolsForm
        @mapToolsUpdated="updateMapTools()"
        @landmarkUpdated="updateLandmarks()"
      />
    </div>
  </div>
</template>

<script>

import {
  LMap, LTileLayer, LMarker, LIcon,
  LControl,
} from '@vue-leaflet/vue-leaflet';
import Button from '@/components/widgets/button.vue';
import L from 'leaflet';
import 'leaflet-draw';
import MapToolsForm from '@/components/commandBoard/forms/mapToolsForm.vue';
import {
  mapToolsComputed, incidentsComputed, layersComputed, mapToolsMethods, authComputed,
  landmarksComputed, landmarksMethods, tasksComputed, tasksMethods,
} from '@/state/helpers';
import { showMessage } from '@/components/widgets/swalUtils';
import defaultExternalLayers from '@/components/commandBoard/maps/defaultExternalLayers';
import { tiledMapLayer } from 'esri-leaflet';
import {
  capitalizeFirstLetter, getGoogleLayer, getGoogleMapType, defaultGoogleLayerName,
} from '@/components/commandBoard/maps/googleMapsUtils';
import IncidentResource from '@/components/commandBoard/resourcesManagement/incidentResource.vue';
import DrawLandmark from '@/components/commandBoard/maps/drawLandmark';
import getDrawToolbarOptions from '@/components/commandBoard/utils/mapUtils';
import { toLatLng } from 'leaflet/src/geo/LatLng';

export default {
  components: {
    MapToolsForm,
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    LControl,
    IncidentResource,
    Button,
  },
  data() {
    return {
      mapIsReady: false,
      mapDrawersInitialized: false,
      object: null,
      tool: null,
      isEditing: false,
      zoom: 18,
      mapOptions: {
        attributionControl: false,
        zoomControl: false,
      },
      layers: [],
      drawnItems: null,
      drawControl: null,
      center: null,
      markers: [],
      landmarks: [],
    };
  },
  computed: {
    ...mapToolsComputed,
    ...landmarksComputed,
    ...incidentsComputed,
    ...layersComputed,
    ...authComputed,
    ...tasksComputed,
  },
  watch: {
    async getCurrentIncident(incident) {
      if (incident?.coordinates) {
        this.isEditing = false;
        this.$refs.map.leafletObject.setView(
          [
            incident.coordinates.points.latitude,
            incident.coordinates.points.longitude,
          ],
        );
        await this.loadMapTools({
          user: JSON.parse(this.currentUser),
          incident: this.getCurrentIncident,
          invalidate: true,
        });
        await this.initializeMapTools();
      }
      if (this.mapIsReady && !this.mapDrawersInitialized) {
        this.addDrawControls();
        this.mapDrawersInitialized = true;
      }
    },
    getCurrentMapTools: {
      handler(mapTools) {
        if (mapTools?.length) {
          this.initializeMapTools();
          this.isEditing = false;
        }
      },
      deep: true,
    },
    getCurrentLayer: {
      async handler(layers) {
        await this.initializeMapTools();
        await this.loadLandmarks({
          user: JSON.parse(this.currentUser),
          layers,
        });
        this.isEditing = false;
      },
      deep: true,
    },
  },
  methods: {
    ...landmarksMethods,
    ...mapToolsMethods,
    ...tasksMethods,
    getMapClickLocation(e) {
      if (this.getListenMapLocationStatus && e?.latlng !== undefined) {
        this.setCurrentTask({
          task: {
            ...this.getCurrentTask,
            latitude: e.latlng.lat.toString(),
            longitude: e.latlng.lng.toString(),
          },
        });
        this.switchClickLocationStatus({ status: false });
      }
    },
    updateMapCenter(coordinates) {
      try {
        this.$refs.map.leafletObject.setView(
          [
            coordinates.latitude,
            coordinates.longitude,
          ],
        );
      } catch (e) {
        console.log('Unable to center.', e.message);
      }
    },
    disableMapScroll() {
      this.mapOptions.scrollWheelZoom = !this.mapOptions.scrollWheelZoom;
    },
    markerEditing(event, marker) {
      this.setEditingMapTools({ data: marker, tool: event });
      this.isEditing = true;
    },
    landmarkEditing(event, landmark) {
      this.setEditingMapTools(
        {
          data:
            {
              ...landmark,
              type: 'landmark',
              lat: event.latlng.lat,
              lng: event.latlng.lng,
            },
          tool: event,
        },
      );
      this.isEditing = true;
    },
    getFormattedAssetUrl(url) {
      if (url.match('/img/maptools/')) {
        return url.toString().replace('/img/maptools', 'maptools');
      }
      return `maptools/${url}`;
    },
    getFormattedLandmarkAssetUrl(url) {
      return url.toString().replace('/img/landmarks', 'landmarks');
    },
    async clearLayers() {
      const { _layers } = this.$refs.map.leafletObject;

      // TODO: make differential removal of mapTools
      await Object.keys(_layers).forEach((key) => {
        if (_layers[key]) {
          if (
            parseInt(_layers[key].options?.attribution, 10)
          ) {
            try {
              this.$refs.map.leafletObject.removeLayer(_layers[key]);
            } catch (e) {
              console.log(e);
            }
          }
        }
      });
    },
    async initializeMapTools() {
      await this.clearLayers();

      this.markers = [];

      const tools = await this.filterMapTools({ layers: this.getCurrentLayer });
      const toolsObjects = [];
      Object.keys(tools).forEach((index) => {
        const { type } = tools[index];
        const location = toLatLng(tools[index].lat, tools[index].lng);

        switch (type) {
          case 'marker':
            this.markers.push(tools[index]);
            break;
          case 'polyline':
            toolsObjects.push(L.polyline(this.convertPoints(tools[index].line_points), {
              color: tools[index].color,
              attribution: tools[index].id,
            }).on('click', (e) => {
              this.setEditingMapTools({ tool: e, data: tools[index] });
              e.target.editing.enable();
              this.isEditing = true;
            }));
            break;

          case 'polygon':
            toolsObjects.push(L.polygon(this.convertPoints(tools[index].line_points), {
              color: tools[index].color,
              attribution: tools[index].id,
            }).on('click', (e) => {
              this.setEditingMapTools({ tool: e, data: tools[index] });
              e.target.editing.enable();
              this.isEditing = true;
            }));
            break;

          case 'rectangle':
            toolsObjects.push(L.rectangle(this.convertPoints(tools[index].line_points), {
              color: tools[index].color,
              attribution: tools[index].id,
            }).on('click', (e) => {
              this.setEditingMapTools({ tool: e, data: tools[index] });
              e.target.editing.enable();
              this.isEditing = true;
            }));
            break;

          case 'circle':
            toolsObjects.push(L.circle(
              location,
              {
                fill: true,
                'fill-opacity': 0.4,
                'fill-color': tools[index].color,
                radius: tools[index].radius * 1609.34,
                color: tools[index].color,
                attribution: tools[index].id,
              },
            ).on('click', (e) => {
              this.setEditingMapTools({ tool: e, data: tools[index] });
              // TODO: enable editing only when able to resize and update the form
              // For now, only on the form is possible to change the radius
              // e.target.editing.enable();
              this.isEditing = true;
            }));
            break;
          default:
            break;
        }
      });
      Object.keys(toolsObjects).forEach((index) => {
        toolsObjects[index].addTo(this.$refs.map.leafletObject);
      });
    },
    convertPoints(address) {
      const points = [];
      if (address) {
        Object.keys(address).forEach((index) => {
          try {
            points.push([address[index].x, address[index].y]);
          } catch (e) {
            showMessage({
              title: 'Problem loading the map tools',
              message: `We've found some issues while loading the map tools of this incident.${e}`,
              icon: 'error',
            });
          }
        });
      }
      return points;
    },
    async updateMapTools() {
      this.isEditing = false;
      await this.loadMapTools({
        user: JSON.parse(this.currentUser),
        incident: this.getCurrentIncident,
        invalidate: true,
      });
      await this.initializeMapTools();
    },
    async updateLandmarks() {
      this.isEditing = false;
      await this.clearLayers();
      await this.loadLandmarks({
        user: JSON.parse(this.currentUser),
        layers: this.getCurrentLayer,
      });
      await this.initializeMapTools();
    },
    async addDrawControls() {
      Object.keys(defaultExternalLayers).forEach((key) => {
        const label = defaultExternalLayers[key].name;
        const { url, maxZoom } = defaultExternalLayers[key];
        let baseLayer;

        if (url.match('openstreetmap')) {
          baseLayer = L.tileLayer(url, {
            maxZoom,
            maxNativeZoom: 18,
          });
        }

        if (url.match('weather')) {
          baseLayer = L.tileLayer(url, {
            attribution: 'Weather data',
          });
        }

        if (url.match('arcgisonline')) {
          baseLayer = tiledMapLayer({
            url,
            maxZoom,
          });
        }

        this.layers[label] = baseLayer;
      });

      Object.keys(defaultGoogleLayerName).forEach((key) => {
        const mapType = getGoogleMapType(defaultGoogleLayerName[key]);
        this.layers[capitalizeFirstLetter(mapType)] = getGoogleLayer(mapType);
      });

      L.control.layers(this.layers, [], {
        position: 'bottomleft',
      }).addTo(this.$refs.map.leafletObject);

      const drawnItems = new L.FeatureGroup();

      const drawLandmark = new DrawLandmark(this.$refs.map.leafletObject);
      drawLandmark.modifyModeHandlers();

      this.drawControl = new L.Control.Draw(getDrawToolbarOptions);

      this.$refs.map.leafletObject.addLayer(drawnItems);
      this.$refs.map.leafletObject.addControl(this.drawControl);
      this.$refs.map.leafletObject.on(L.Draw.Event.CREATED, (e) => {
        if (
          e.layerType !== 'marker'
          && e.layerType !== 'landmark'
          && e.layerType !== 'circle'
        ) {
          this.setEditingMapTools(
            {
              tool: { ...e },
              data: {
                type: e.layerType,
              },
            },
          );
          // TODO: enable editing during creation causes vuex exception
          // e.layer.editing.enable();
        } else {
          this.setEditingMapTools(
            {
              tool: { ...e },
              data: {
                date: new Date().toISOString().slice(0, 10),
                type: e.layerType,
                lat: e.layer.getLatLng().lat,
                lng: e.layer.getLatLng().lng,
              },
            },
          );
        }
        e.layer.options.attribution = '01';
        drawnItems.addLayer(e.layer);
        this.isEditing = true;
      });

      if (this.getCurrentIncident?.coordinates) {
        this.$refs.map.leafletObject.setView(
          [
            this.getCurrentIncident.coordinates.points.latitude,
            this.getCurrentIncident.coordinates.points.longitude,
          ],
        );
      }
      if (this.getCurrentIncident) {
        await this.loadMapTools({
          user: JSON.parse(this.currentUser),
          incident: this.getCurrentIncident,
          invalidate: true,
        });
        await this.loadLandmarks({
          user: JSON.parse(this.currentUser),
          layers: this.getCurrentLayer,
        });
        await this.initializeMapTools();
      }
    },
  },
};

</script>
<style>

.leaflet-draw-toolbar .leaflet-draw-draw-landmark {
  background-image: url("/img/landmark.b6642691.svg");
  background-size: 13px 13px;
  background-color: #FFF;
}

</style>
