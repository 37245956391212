<template>
    <div class="d-flex">
      <b-dropdown no-caret class="my-auto">
        <template #button-content>
          <span>Layers </span>
          <i
             :class="enableDropdown ? 'fas fa-caret-down'
               : 'bx bx-hourglass bx-spin font-size-16 align-middle me-2'"
          ></i>
        </template>
        <div v-for="(layer, index) in getLayers" :key="index"
             class="dropdown-item bg-white text-body">
          <b-form-checkbox :checked="layer.name === 'None'"
            @change="updateSelectedLayers(layer)"
          >
            {{
              layer.name
            }}
          </b-form-checkbox>
        </div>
      </b-dropdown>
    </div>
</template>
<script>
import {
  layersComputed,
  layersMethods,
  authComputed,
}
  from '@/state/helpers';

export default {
  data() {
    return {
      enableDropdown: false,
    };
  },
  computed: {
    ...layersComputed,
    ...authComputed,
  },
  methods: {
    ...layersMethods,
    async updateSelectedLayers(layer) {
      await this.changeCurrentLayer({ payload: layer });
    },
  },
  async mounted() {
    if (this.getLayers.length === 1) {
      await this.loadLayers({ user: JSON.parse(this.currentUser) });
      this.enableDropdown = true;
    }
  },
};
</script>
