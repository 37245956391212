<template>
  <b-dropdown id="dropdown-dropup" variant="primary">
    <template v-slot:button-content>
      <div class="p-2 w-75">
        <i class="fas fa-toolbox toolbar" />
      </div>
    </template>
    <b-dropdown-item v-for="(item, index) in items"
                     :key="index" @click="switchModal(item)">
      <div :class="['row p-2 rounded-2 dropdown',
       item.enabled ? 'bg-primary text-white' : 'bg-secondary']">
        <div class="col-2">
          <i :class="[item.icon, 'm-auto']" />
        </div>
        <div class="col">
          {{item.name}}
        </div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
  <!-- Add Resource window -->
  <Window v-if="items.add_resource.active" minWidth="600px" minHeight="280px">
    <template v-slot:header>
      <div class="d-flex p-0 m-0 justify-content-end bg-secondary">
        <b-button variant="danger" size="sm" class="m-1"
                  @click="items.add_resource.active =! items.add_resource.active">
          <span class="m-auto">X</span>
        </b-button>
      </div>
    </template>
    <template v-slot:main>
      <div class="mt-2">
        <AddResource :active="items.add_resource.active" />
      </div>
    </template>
  </Window>
  <!-- Resources window -->
  <Window v-if="items.resources.active" minWidth="370px" minHeight="450px">
    <template v-slot:header>
      <div class="d-flex p-0 m-0 justify-content-end bg-secondary">
        <b-button variant="danger" size="sm" class="m-1"
                  @click="items.resources.active =! items.resources.active">
          <span class="m-auto">X</span>
        </b-button>
      </div>
    </template>
    <template v-slot:main>
      <div class="mt-2">
        <ResourcesContent @centerResource="updateMapCenter" :active="items.resources.active" />
      </div>
    </template>
  </Window>
  <!-- Tasks window -->
  <Window minWidth="800px" minHeight="500px" v-if="items.tasks.active">
    <template v-slot:header>
      <div class="d-flex p-0 m-0 justify-content-end bg-secondary">
        <b-button variant="danger" size="sm" class="m-1"
                  @click="items.tasks.active =! items.tasks.active">
          <span class="m-auto">X</span>
        </b-button>
      </div>
    </template>
    <template v-slot:main>
      <div class="mt-2">
        <TasksContent :active="items.tasks.active" />
      </div>
    </template>
  </Window>
</template>
<script>
import AddResource from '@/components/commandBoard/windowContent/addResource.vue';
import ResourcesContent from '@/components/commandBoard/windowContent/resourcesContent.vue';
import TasksContent from '@/components/commandBoard/windowContent/tasksContent.vue';
import Window from '@/components/commandBoard/utils/window.vue';

export default {
  components: {
    AddResource,
    ResourcesContent,
    Window,
    TasksContent,
  },
  emits: [
    'mouseOverWindow',
    'mapFocus',
  ],
  data() {
    return {
      expanded: true,
      items: {
        add_resource: {
          name: 'Add Resource',
          enabled: true,
          icon: 'fas fa-add',
          active: false,
        },
        resources: {
          name: 'Resources',
          enabled: true,
          icon: 'fas fa-user-circle',
          active: false,
        },
        tasks: {
          name: 'Tasks',
          enabled: true,
          icon: 'fas fa-arrow-right',
          active: false,
        },
        checkin: {
          name: 'Check-In',
          enabled: false,
          icon: 'fas fa-check',
          active: false,
        },
        notes: {
          name: 'Notes',
          enabled: false,
          icon: 'fas fa-file',
          active: false,
        },
      },
    };
  },
  methods: {
    updateMapCenter(coordinate) {
      return this.$emit('mapFocus', coordinate);
    },
    disableScroll() {
      return this.$emit('mouseOverWindow');
    },
    switchModal(item) {
      item.active = !item.active;
    },
  },
};
</script>
<style scoped>
.toolbar {
  width: 15px;
  height: 15px;
}

.dropdown {
  width: 160px;
}

</style>
