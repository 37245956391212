<template>
  <div class="container" v-if="showButtons">
    <div class="buttons">
      <b-button class="mx-5 my-4" @click="addUnit"><i class="bx bx-car"></i></b-button>
    </div>
    <div class="buttons">
      <b-button class="mx-5 my-4" @click="addPersonnel"><i class="bx bx-user-plus"></i></b-button>
    </div>
  </div>
  <div id="new-personnel" class="mx-4" v-if="showAddPersonnel">
    <h4>Add Personnel</h4>
    <div class="row">
      <div class="col-lg-4 px-1">
        <b-form-input id="first-name" v-model="newPersonnelData.first_name"
                      placeholder="First name">
        </b-form-input>
      </div>
      <div class="col-lg-4 px-1">
        <b-form-input id="last-name" v-model="newPersonnelData.last_name"
                      placeholder="Last name">
        </b-form-input>
      </div>
      <div class="col-lg-4 px-1">
        <b-form-input id="primary-email" v-model="newPersonnelData.primary_email"
                      placeholder="Email">
        </b-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 p-1">
        <b-form-select id="country-code"
          :options="stateOptions" v-model="newPersonnelData.country_code"
          class="form-select" type="text">
        </b-form-select>
      </div>
      <div class="col-lg-4 p-1">
        <b-form-input id="cell-phone" v-model="newPersonnelData.cell_phone"
                      placeholder="Cell Phone #"></b-form-input>
      </div>
      <div class="col-lg-4 p-1">
        <b-form-select
          id="gender"
          :options="genderOptions"
          v-model="newPersonnelData.gender"
          class="form-select"
          placeholder="State"
          type="text"
        ></b-form-select>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 px-1">
        <b-form-select
          id="assign-to-unit"
          :options="unitOptions"
          v-model="newPersonnelData.assign_to_unit"
          class="form-select"
          placeholder="State"
          type="text"
        ></b-form-select>
      </div>
    </div>
    <br>
    <div class="m-3">
      <b-button class="mx-2" @click="savePersonnel">Save</b-button>
      <b-button class="mx-2" @click="close">Close</b-button>
    </div>
  </div>
  <div id="new-unit" class="mx-4" v-if="showAddUnit">
    <h4>Add Unit</h4>
    <div class="row">
      <div class="col-lg-4 px-1">
        <b-form-input id="unit-name" v-model="newUnitData.name"
                      placeholder="Unit Name">
        </b-form-input>
      </div>
      <div class="col-lg-4 px-1">
        <b-form-input id="unit-call-sign" v-model="newUnitData.shortname"
                      placeholder="Call Sign">
        </b-form-input>
      </div>
    </div>
    <br>
    <div class="m-3">
      <b-button class="mx-2" @click="saveUnit">Save</b-button>
      <b-button class="mx-2" @click="close">Close</b-button>
    </div>
  </div>
</template>

<script>
import countries from '@/components/countriesDict';
import {
  authComputed,
  unitsComputed,
  personnelMethods,
  unitsMethods,
} from '@/state/helpers';

export default {
  name: 'addResource',
  data() {
    return {
      newPersonnelData: {
        first_name: '',
        last_name: '',
        primary_email: '',
        country_code: '',
        cell_phone: '',
        gender: '',
        assign_to_unit: '',
      },
      stateOptions: countries,
      genderOptions: [
        // Based on the DB definition
        { value: '', text: 'Choose Gender' },
        { value: 'Male', text: 'Male' },
        { value: 'Female', text: 'Female' },
      ],
      unitOptions: [
        { value: '', text: 'Assign to Unit:' },
      ],
      newUnitData: {
        name: '',
        shortname: '',
      },
      showButtons: true,
      showAddUnit: false,
      showAddPersonnel: false,
    };
  },
  computed: {
    ...authComputed,
    ...unitsComputed,
  },
  methods: {
    ...personnelMethods,
    ...unitsMethods,
    async savePersonnel() {
      // Save Personnel
      await this.createPersonnel({
        user: JSON.parse(this.currentUser),
        newPersonnelData: this.newPersonnelData,
      }).then(() => {
        this.close();
      });
    },
    async saveUnit() {
      // Save Unit
      await this.createUnit({
        user: JSON.parse(this.currentUser),
        newUnitData: this.newUnitData,
      }).then(() => {
        this.close();
      });
    },
    addPersonnel() {
      this.showButtons = false;
      this.showAddUnit = false;
      this.showAddPersonnel = true;
    },
    addUnit() {
      this.showButtons = false;
      this.showAddPersonnel = false;
      this.showAddUnit = true;
    },
    close() {
      this.showAddUnit = false;
      this.showAddPersonnel = false;
      this.showButtons = true;
      Object.keys(this.newPersonnelData).forEach((key) => {
        this.newPersonnelData[key] = '';
      });
      Object.keys(this.newUnitData).forEach((key) => {
        this.newUnitData[key] = '';
      });
    },
  },
  mounted() {
    // Fix units loading on main component mount.
    if (!this.getClientUnits?.length) {
      this.loadClientUnits({
        user: JSON.parse(this.currentUser),
        loadFull: true,
        invalidate: true,
      }).then(() => {
        this.getClientUnits.forEach((unit) => {
          this.unitOptions.push({ value: unit.id, text: unit.name });
        });
      });
    } else {
      this.getClientUnits.forEach((unit) => {
        this.unitOptions.push({ value: unit.id, text: unit.name });
      });
    }
  },
};
</script>

<style scoped>
.container {
  text-align: center;
}

.buttons {
  display: inline;
}

#new-personnel {
  overscroll-behavior: contain;
}

.bx {
  font-size: 5rem;
}
</style>
