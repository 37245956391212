<template>
  <div class="d-flex z-3">
    <b-dropdown no-caret :disabled="!getActiveIncidents" class="my-auto">
      <template #button-content>
          <span>Incident </span>
          <i :class="getActiveIncidents ? 'fas fa-caret-down'
           : 'bx bx-hourglass bx-spin font-size-16 align-middle me-2'" />
      </template>
      <b-dropdown-item @click="updateIncident(incident)"
        v-for="(incident, index) in getActiveIncidents"
       :key="index">
        {{ incident.name }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import {
  mapToolsMethods, incidentsComputed, incidentsMethods, authComputed,
  tasksMethods,
} from '@/state/helpers';
import { showMessage } from '@/components/widgets/swalUtils';

export default {
  data() {
    return {
      selectedIncident: null,
    };
  },
  computed: {
    ...incidentsComputed,
    ...authComputed,
  },
  methods: {
    ...incidentsMethods,
    ...mapToolsMethods,
    ...tasksMethods,
    async updateIncident(incident) {
      if (typeof incident.coordinates === 'undefined') {
        incident = await this.loadIncident({
          user: JSON.parse(this.currentUser),
          incident_id: incident.id,
        });
      }

      if (!this.selectedIncident) {
        this.selectedIncident = incident;
        await this.setCurrentIncident(incident);
        await this.setTasksCurrentIncident(incident);
        return this.loadMapTools({
          user: JSON.parse(this.currentUser),
          incident: this.getCurrentIncident,
          layer: this.getCurrentLayer ? this.getCurrentLayer : null,
        });
      }
      if (this.selectedIncident?.id !== incident?.id) {
        this.selectedIncident = incident;
        await this.setCurrentIncident(incident);
        return this.loadMapTools({
          user: JSON.parse(this.currentUser),
          incident: this.getCurrentIncident,
          layer: this.getCurrentLayer ? this.getCurrentLayer : null,
        });
      }
      return showMessage({
        title: 'Incident already selected!',
        icon: 'info',
      });
    },
  },
  async mounted() {
    if (!this.getActiveIncidents) {
      await this.loadActiveIncidents({ user: JSON.parse(this.currentUser) });
    }
  },
};

</script>
