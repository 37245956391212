<template>
  <div class="d-flex flex-row justify-content-center gap-2">
    <!-- Left Caret -->
    <b-button
      variant="primary"
      size="sm"
      @click="changePage('previous')"
    >
      <i class="m-auto fas fa-caret-left" />
    </b-button>
    <div v-for="(page, index) in totalPages" :key="index">
      <b-button
        @click="changePage(index + 1)"
        size="sm"
        :variant="currentPage === (index + 1) ? 'success' : 'secondary'"
      >
        {{ index + 1 }}
      </b-button>
    </div>
    <!-- Right Caret -->
    <b-button
      variant="primary"
      size="sm"
      @click="changePage('next')"
    >
      <i class="m-auto fas fa-caret-right" />
    </b-button>
  </div>
</template>
<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    changePage(action) {
      return this.$emit('changePage', action);
    },
  },
};

</script>
