import L from 'leaflet';
import 'leaflet-draw';

// TODO: Check rhodium 3 version for drawing events interception
//  to don't miss the events and lose the user work when updating the socket mapTools.
export default class DrawState {
  /**
   * Create draw layer
   *
   * Save a reference of the L.Draw.Polyline || L.Draw.Polygon being created
   *  internally when the leaflet-draw toolbar is clicked
   *
   * @param {L.Map} map
   * @param {String} layerType
   * @param {Object} options - this.options.polygon
   * @return {L.Draw.Polyline || L.Draw.Polygon}
   */
  createDrawLayer(layerType, map, options) {
    let layer = {};

    switch (layerType) {
      case 'polyline':
        layer = new L.Draw.Polyline(map, options);
        this.polyline = layer;
        break;
      case 'polygon':
        layer = new L.Draw.Polygon(map, options);
        this.polygon = layer;
        break;
      default:
    }

    return layer;
  }
}
