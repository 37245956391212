import L from 'leaflet';
import 'leaflet-draw';
import DrawState from '@/components/commandBoard/maps/drawState';
import getDrawToolbarOptions from '@/components/commandBoard/utils/mapUtils';
/**
 * Add landmarks button to leaflet-draw toolbar
 *
 * Implements everything necessary for the Landmarks button
 */
export default class DrawLandmark {
  constructor(map) {
    /**
     * Add the button mouseover title
     */
    L.drawLocal.draw.toolbar.buttons.landmark = 'Draw a landmark';

    /**
     * Add tooltip text
     */
    L.drawLocal.draw.handlers.landmark = {};
    L.drawLocal.draw.handlers.landmark.tooltip = {};
    L.drawLocal.draw.handlers.landmark.tooltip.start = 'Click map to place landmark';

    /**
     * Store the options to be used in other places
     */
    this.options = getDrawToolbarOptions.draw;

    /**
     * Create draw landmark
     */
    L.Draw.Landmark = L.Draw.Marker.extend({
      initialize(map, options) {
        this.options = options;
        // define the layer type
        this.layerType = 'landmark';

        // used in setting up `.leafet-draw-draw-<this.type>`,
        // so `type` must not clash with other draw types on the toolbar
        this.type = 'landmark';

        // sets up showing a tooltip for a landmark
        // eslint-disable-next-line
        this._initialLabelText = L.drawLocal.draw.handlers.landmark.tooltip.start;

        L.Draw.Feature.prototype.initialize.call(this, map, options);
      },
    });
    this.drawState = new DrawState(map);
  }

  /**
   * Modify `getModeHandlers` with custom function to get access to polylines / polygons
   *
   * Used in restoring the `pre-L.Draw.Event.CREATED` state
   *
   * NOTES: This method uses calls the original `getModeHandlers` method and
   * sets custom mode handlers for polygon and polyline which allows rolling back
   * draw state if user is interrupted when drawing a polyline or polygon.
   *
   * @return void
   */
  modifyModeHandlers() {
    const fn = L.DrawToolbar.prototype.getModeHandlers;
    L.DrawToolbar.prototype.getModeHandlers = (map) => {
      const types = fn.call(this, map)
        .map((obj) => {
          const { handler: { type } } = obj;
          if (type === 'polygon' || type === 'polyline') {
            obj.handler = this.drawState.createDrawLayer(type, map, this.options[type]);
          }
          return obj;
        });
      return [
        // placed here to maintain the button render order
        ...types,
        // add landmark mode handler
        {
          enabled: this.options.landmark,
          handler: new L.Draw.Landmark(map, this.options.landmark),
          title: L.drawLocal.draw.toolbar.buttons.landmark,
        },
      ];
    };
  }
}
