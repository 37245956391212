const apiKey = '48f4bf4bb6d2ff3826ea5fdb8934d68d';
export default {
  weather: {
    id: 'weather',
    name: 'Weather Precipitation',
    attribution: 'Weather',
    url: `https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=${apiKey}`,
  },
  weatherTemperature: {
    id: 'weather',
    name: 'Weather Temperature',
    attribution: 'Weather',
    url: `https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?appid=${apiKey}`,
  },
  weatherClouds: {
    id: 'weather',
    name: 'Weather Clouds',
    attribution: 'Weather',
    opacity: 0.7,
    url: `https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=${apiKey}`,
  },
  openstreet: {
    id: 'openstreet',
    name: 'OpenStreetMap.org',
    type: 'openstreet',
    img_src: '/img/label_openstreet.svg',
    maxZoom: 20,
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
  arc_usa_topo: {
    id: 'arc_usa_topo',
    name: 'ArcGIS USA Topo Map',
    type: 'arc_usa_topo',
    img_src: '/img/label_esri.png',
    maxZoom: 15,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/USA_Topo_Maps/MapServer/',
  },
  world_topo: {
    id: 'world_topo',
    name: 'ArcGIS World Topo Map',
    type: 'world_topo',
    img_src: '/img/label_esri.png',
    maxZoom: 18,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/',
  },
  world_imagery: {
    id: 'world_imagery',
    name: 'ArcGIS World Basemap',
    type: 'world_imagery',
    img_src: '/img/label_esri.png',
    maxZoom: 18,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/',
  },
  light_gray: {
    id: 'light_gray',
    name: 'ArcGIS Light Gray',
    type: 'light_gray',
    img_src: '/img/label_esri.png',
    maxZoom: 16,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/',
  },
  ocean_basemap: {
    id: 'ocean_basemap',
    name: 'ArcGIS Ocean Basemap',
    type: 'ocean_basemap',
    img_src: '/img/label_esri.png',
    maxZoom: 10,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/',
  },
};
