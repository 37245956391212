import L from 'leaflet';

const metric = 'metric';
const color = '#ff0000'; // Color the shape will turn when intersects
const errorColor = '#ffff00'; // Message that will show when intersect
const errorMessage = '<strong>Oh snap!<strong> you can\'t draw that!'; // Message that will show when intersect
const weight = 2;
const shapeOptions = {
  color,
  weight,
};
const getDrawToolbarOptions = {
  position: 'bottomright',
  draw: {
    polygon: {
      allowIntersection: false,
      showArea: true,
      metric,
      drawError: {
        color: errorColor,
        message: errorMessage,
      },
      shapeOptions,
    },
    polyline: {
      metric,
      shapeOptions,
    },
    circle: {
      metric,
      shapeOptions,
    },
    rectangle: {
      metric,
      shapeOptions,
    },
    marker: {
      icon: L.icon({
        iconUrl: require('@/assets/images/maptools/default.png'),
      }),
    },
    landmark: {
      icon: L.icon(
        {
          iconUrl: require('@/assets/images/landmarks/default.png'),
          iconSize: [40, 40],
          iconAnchor: [20, 40],
          type: 'landmark',
        },
      ),
    },
    circlemarker: false,
  },
};

export default getDrawToolbarOptions;
