<template>
  <div v-if="active" :class="getListenMapLocationStatus ? 'cursor-crosshair' : ''">
    <!-- Search bar -->
    <div class="row">
      <div class="col-2">
        <div class="mx-auto d-flex flex-column justify-content-center" v-if="getLoadingTasksStatus">
          <b-spinner label="Loading Tasks" variant="info" small />
          <i class="fas fa-clipboard-list" />
        </div>
        <div class="mx-auto d-flex flex-row justify-content-center" v-else>
          <Button
            variant="primary"
            @click="openTaskForm"
            :data="{
              text: 'Task',
              icon: 'fas fa-plus',
              description: 'Click on the map to set the location of the task.'
            }"
          />
        </div>
      </div>
      <div class="col-9">
        <b-form-floating-label title="Search" />
        <b-form-input
          v-model="search"
          placeholder="Search for task name"
        />
      </div>
    </div>
    <!-- Listing -->
    <div class="row">
      <div class="col">
        <table class="table table-hover">
          <thead>
            <tr class="text-center">
              <th>Tasks</th>
              <th>Callsign</th>
              <th>Unit</th>
              <th>Personnel</th>
              <th>Location</th>
              <th>Notes</th>
              <th>Start Time</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(task, index) in getTasksPaginated" :key="index" class="row-highlight">
              <td class="col cursor-pointer content-highlight"
                  @click="setCurrentTask({ task })"
                  @keydown="setCurrentTask({task})"
              >
                {{ task.name }}
              </td>
              <td class="col">{{ task.callsign }}</td>
              <td class="col-2">
                <div class="d-flex flex-row gap-1 justify-content-center">
                  <b-avatar
                    size="20px"
                    v-for="(unit, index) in task.units"
                    :key="index"
                    variant="info"
                  >
                    <template v-slot>
                      <div class="d-flex justify-content-center">
                        <span
                          class="text-white mt-1"
                        >
                          {{ unit.name.slice(0,2) }}
                        </span>
                      </div>
                    </template>
                  </b-avatar>
                </div>
              </td>
              <td class="col-2">
                <!-- get status for variant -->
                <div class="d-flex flex-row gap-1 justify-content-center">
                  <b-avatar
                    size="20px"
                    v-for="(personnel, index) in task.personnel"
                    :key="index"
                  >
                    <template v-slot>
                      <div class="d-flex justify-content-center">
                        <span
                          class="text-white mt-1"
                        >
                          {{ personnel.first_name.slice(0,2) }}
                        </span>
                      </div>
                    </template>
                  </b-avatar>
                </div>
              </td>
              <td class="col">
              <span
                class="d-inline-block text-truncate location-column"
              >
                {{ task.location }}
              </span>
              </td>
              <td class="col">
                {{ task.notes }}
              </td>
              <td class="col">
                {{ getFormattedTime(task.start_time) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="position-relative h-100">
      <Pagination
        @changePage="controlPageSwitch"
        :currentPage="getTasksCurrentPage"
        :totalPages="getTasksTotalPages"
      />
    </div>
    <tasksForm :available="formControl" />
  </div>
</template>
<script>
import {
  tasksComputed, tasksMethods, authComputed,
} from '@/state/helpers';
import Pagination from '@/components/widgets/pagination.vue';
import tasksForm from '@/components/commandBoard/forms/tasksForm.vue';
import Button from '@/components/widgets/button.vue';

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Pagination,
    Button,
    tasksForm,
  },
  data() {
    return {
      wantsToOpen: false,
      search: '',
      formControl: false,
    };
  },
  computed: {
    ...tasksComputed,
    ...authComputed,
  },
  watch: {
    search(data) {
      this.updateSearch({ text: data });
    },
    getListenMapLocationStatus(status) {
      this.formControl = !status;
    },
  },
  methods: {
    ...tasksMethods,
    openTaskForm() {
      this.wantsToOpen = true;
      this.switchClickLocationStatus({ status: true });
    },
    controlPageSwitch(action) {
      if (action === 'next' || action === 'previous') {
        return this.pageControl({ action });
      }
      const page = action;
      action = 'goto';
      return this.pageControl({ action, page });
    },
    getFormattedTime(time) {
      try {
        return new Date(time).toLocaleDateString('en-us');
      } catch (e) {
        console.log(e);
        return new Date().toLocaleDateString('en-us');
      }
    },
  },
  mounted() {
    if (!this.getTasks?.length) {
      this.loadTasks({
        user: JSON.parse(this.currentUser),
        invalidate: true,
      });
    }
  },
};

</script>
<style scoped>
.location-column {
  max-width: 150px;
}
</style>
