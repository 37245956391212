<template>
  <b-button
    :class="['custom-tooltip', customClasses]"
    :disabled="disable"
    @click.prevent="clickEvent()"
    :variant="variant"
  >
    <slot name="content" :data="content">
      <div class="d-flex flex-row gap-2">
        <i v-if="content?.icon" :class="content?.icon" />
        <span v-if="content?.text">{{ content.text }}</span>
      </div>
    </slot>
    <span :class="getTooltipDirection">
      {{ content.description }}
    </span>
  </b-button>
</template>
<script>
export default {
  computed: {
    content() {
      return this.data;
    },
    getTooltipDirection() {
      return `custom-tooltip-${this.tooltipPosition}`;
    },
  },
  props: {
    tooltipPosition: {
      type: String,
      default: 'top',
      validator: (value) => ['top', 'bottom', 'left', 'right'].includes(value),
    },
    customClasses: {
      type: String,
      default: '',
    },
    disable: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() { return { text: null, description: null }; },
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => [
        'primary', 'info',
        'danger', 'secondary',
        'success', 'light',
      ].includes(value),
    },
  },
  methods: {
    clickEvent() {
      return this.$emit('click', this.content);
    },
  },
};
</script>
<style scoped>

.custom-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

/* Tooltip top */
.custom-tooltip .custom-tooltip-top {
  visibility: hidden;
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  background-color: #252525;
  color: #ffffff;
  text-align: center;
  padding: 8px 8px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.custom-tooltip:hover .custom-tooltip-top {
  visibility: visible;
}

.tooltip .custom-tooltip-top::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* Tooltip right */
.custom-tooltip .custom-tooltip-right {
  visibility: hidden;
  width: 120px;
  top: -5px;
  left: 105%;
  background-color: #252525;
  color: #ffffff;
  text-align: center;
  padding: 8px 8px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.custom-tooltip:hover .custom-tooltip-right {
  visibility: visible;
}

.tooltip .custom-tooltip-right::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

</style>
