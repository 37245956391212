<template>
  <!-- Modal for add tasks -->
  <b-modal v-model="showModal" size="md" @ok="saveTask" @close="$emit('close')">
    <div class="row text-center">
      <span class="h4 mx-auto">Add Task</span>
    </div>
    <div class="d-flex flex-row gap-2 justify-content-center">
      <div class="d-flex flex-column justify-content-start">
        <b-form-floating-label>Task Type</b-form-floating-label>
        <b-form-select v-model="form" :class="{'is-invalid': v$.form.name.$error}">
          <b-form-select-option
            v-for="(type, index) in getTaskTypes"
            :key="index"
            :value="type"
          >
            {{ type.name }}
          </b-form-select-option>
        </b-form-select>
      </div>
      <div class="d-flex flex-column justify-content-start">
        <b-form-floating-label>Alert</b-form-floating-label>
        <b-form-select v-model="form.alert" :class="{'is-invalid': v$.form.alert.$error}">
          <b-form-select-option
            v-for="(alert, index) in alerts"
            :key="index"
            :value="alert"
          >
            {{ alert.label }}
          </b-form-select-option>
        </b-form-select>
      </div>
      <div v-if="form?.icon">
        <b-img
          class="mt-3"
          :src="require(`@/assets/images/${getFormattedAssetUrl(form.icon)}`)"
          :alt="form.name" />
      </div>
      <div class="d-flex flex-column-reverse justify-content-start">
        <b-button variant="primary" @click="addTypeControl">Add Type</b-button>
      </div>
    </div>
    <div class="d-flex flex-column gap-2 justify-content-start mt-2" v-if="expandedTypeForm">
      <hr />
      <div class="d-flex flex-row justify-content-between mx-2">
        <div class="d-flex flex-column">
          <b-form-floating-label>Name</b-form-floating-label>
          <b-form-input v-model="taskType.name" :class="{'is-invalid': v$.taskType.name.$error}" />
        </div>
        <div class="mt-2">
          <IconSelector resourceRequest="task" @selectedIcon="setTaskTypeIcon" />
        </div>
        <div class="my-auto pt-1">
          <Button
            variant="success"
            :data="{ text: 'Save', icon: 'fas fa-save', description: 'Save new Task Type' }"
            @click="saveTaskType"
          />
        </div>
      </div>
      <hr />
    </div>
    <div class="d-flex flex-row justify-content-center mt-4">
      <b-form-textarea
        :class="[{'is-invalid': v$.form.name.$error}, 'mx-auto']"
        placeholder="Notes..."
        v-model="form.notes"
      />
    </div>
    <div class="d-flex flex-column gap-2 justify-content-start mt-2">
      <div class="d-flex flex-column justify-content-start">
        <b-form-floating-label>CallSign</b-form-floating-label>
        <b-form-input v-model="form.callsign"></b-form-input>
      </div>
    </div>
    <div class="d-flex flex-column gap-2 justify-content-between mt-2">
      <div class="d-flex flex-column justify-content-start">
        <b-form-floating-label>Location</b-form-floating-label>
        <b-form-input v-model="form.location"></b-form-input>
      </div>
    </div>
    <div class="d-flex flex-row gap-2 justify-content-around mt-2">
      <div class="d-flex flex-column justify-content-start">
        <b-form-floating-label>Latitude</b-form-floating-label>
        <b-form-input v-model="form.latitude"></b-form-input>
      </div>
      <div class="d-flex flex-column justify-content-start">
        <b-form-floating-label>Longitude</b-form-floating-label>
        <b-form-input v-model="form.longitude"></b-form-input>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {
  taskTypesMethods, taskTypesComputed, authComputed, incidentsComputed, tasksMethods,
  tasksComputed,
} from '@/state/helpers';
import IconSelector from '@/components/commandBoard/selectors/iconSelector.vue';
import Button from '@/components/widgets/button.vue';
import { showMessage, showError } from '@/components/widgets/swalUtils';
import {
  required, helpers, requiredIf,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    IconSelector,
    Button,
  },
  data() {
    return {
      expandedTypeForm: false,
      showModal: false,
      taskType: {
        name: '',
        icon: '',
      },
      alerts: [
        {
          id: null,
          label: 'No',
        },
        {
          id: 1,
          label: '5 minutes',
        },
        {
          id: 2,
          label: '10 minutes',
        },
      ],
      form: {
        id: null,
        alert: null,
        notes: null,
        callsign: null,
        location: null,
        lat: null,
        lng: null,
        icon: 'G1.png',
      },
    };
  },
  validations: {
    form: {
      alert: {},
      name: {
        required: helpers.withMessage(
          'The name of the task is required',
          required,
        ),
      },
      icon: {
        required: helpers.withMessage(
          'The icon of the task is required',
          required,
        ),
      },
      notes: {},
      callsign: {},
      location: {
        requiredIfPoints: requiredIf(() => !this?.form?.latitude || !this?.form?.longitude),
      },
      lat: {
        requiredIfLatitude: requiredIf(() => !this?.form.location),
      },
      lng: {
        requiredIfLongitude: requiredIf(() => !this?.form.location),
      },
    },
    taskType: {
      name: {
        required: helpers.withMessage(
          'The name of the task is required',
          required,
        ),
      },
      icon: {
        required: helpers.withMessage(
          'The icon of the task is required',
          required,
        ),
      },
    },
  },
  props: {
    available: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    ...taskTypesComputed,
    ...authComputed,
    ...incidentsComputed,
    ...tasksComputed,
  },
  watch: {
    available(newValue) {
      this.showModal = newValue;
    },
    getCurrentTask: {
      handler(task) {
        if (task instanceof Object) {
          Object.assign(this.form, { ...task });
        }
      },
      deep: true,
    },
  },
  methods: {
    ...tasksMethods,
    ...taskTypesMethods,
    async saveTaskType() {
      const { taskType } = this;
      const save = await this.createTaskType({
        user: JSON.parse(this.currentUser),
        taskType,
      });

      console.log(save);

      if (save) {
        this.expandedTypeForm = false;
        this.form.name = taskType.name;
        this.form.icon = taskType.icon;
        return;
      }

      showError("We've got a error while saving the task type");
    },
    setTaskTypeIcon(icon) {
      this.taskType.icon = `/img/task/${icon}`;
    },
    addTypeControl() {
      this.expandedTypeForm = !this.expandedTypeForm;
    },
    getFormattedAssetUrl(icon) {
      const lookup = /\/img\/task/g;
      if (icon.match(lookup)) {
        return icon.toString().replace('/img/task', 'task');
      }
      return `task/${icon}`;
    },
    async saveTask() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return showMessage({
          title: 'Invalid data provided',
          text: 'Check the highlighted error.',
          icon: 'error',
        });
      }

      return this.createTask(
        {
          user: JSON.parse(this.currentUser),
          incident: this.getCurrentIncident,
          form: this.form,
        },
      );
    },
  },
  mounted() {
    if (this.getCurrentTask instanceof Object) {
      Object.assign(this.form, { ...this.getCurrentTask });
    }
    this.loadTaskTypes({
      user: JSON.parse(this.currentUser),
      loadFull: true,
    });
  },
};
</script>
