<template>
  <div>
    <!-- Search bar -->
    <div class="row">
      <div class="col-2">
        <div
          class="mx-auto my-auto d-flex flex-row justify-content-center gap-1"
         v-if="getLoadingUnitsStatus"
        >
          <b-spinner label="Loading Units" variant="info" small />
          <i class="fas fa-car-alt" />
        </div>
      </div>
      <div class="col">
        <b-form-floating-label title="Search" />
        <b-form-input
          v-model="search"
          placeholder="Search for units or personnel"
        />
      </div>
      <div class="col-2">
        <div
          class="mx-auto my-auto d-flex flex-row justify-content-center gap-1"
          v-if="getLoadingPersonnelStatus"
        >
          <b-spinner label="Loading Personnel" variant="success" small />
          <i class="fas fa-user-alt" />
        </div>
      </div>
    </div>
    <!-- Filter switches -->
    <div class="d-flex gap-2 justify-content-center">
      <div class="mt-3" v-for="(filter, index) in filters" :key="index">
        <Button
          :disabled="getLoadingUnitsStatus"
          @click="switchFilter"
          :data="filter"
          :variant="filter.enable ? 'primary' : 'danger'"
        />
      </div>
    </div>
    <!-- Listing -->
    <div>
      <b-card no-body class="mb-1">
        <!-- Stagged -->
        <b-card-header class="p-1">
          <b-button
            class="w-100 p-1 d-flex gap-2 justify-content-start"
            @click="switchCollapse('staged')"
            :variant="expandedStagged ? 'primary' : 'info'"
          >
            <b-badge variant="secondary" class="my-auto">
              {{ getClientStaggedUnits?.length }}
            </b-badge>
            <span class="bold my-auto">Stagged</span>
          </b-button>
        </b-card-header>
        <b-collapse v-model="expandedStagged" class="listing">
          <b-card-body
            v-for="(unit, index) in getClientStaggedUnits"
            :key="index"
            class="row border p-1 m-1 row-highlight"
          >
            <b-card-text class="m-0 p-0 custom-width" v-if="unit?.selected">
              <div class="h-100 ml-auto bg-secondary">&nbsp;</div>
            </b-card-text>
            <b-card-text
              class="m-auto col-1 cursor-pointer"
              @click="selectUnit(unit)"
            >
                <i class="fas fa-car-alt m-auto" />
            </b-card-text>
            <b-card-text class="my-auto col">
              <div class="row justify-content-center">
                <!-- Unit name -->
                <div
                  class="my-auto col content-highlight rounded-2 text-center cursor-pointer"
                  @click="selectUnit(unit)"
                  @keydown="selectUnit(unit)"
                >
                  <span class="font-weight-semibold">{{ unit.name }}</span>
                </div>
                <!-- Status and Task selects -->
                <div class="col">
                  <b-dropdown
                    v-if="unit?.selected"
                    v-model="selectedStatus"
                    size="sm"
                  >
                    <template v-slot:button-content>
                      <span class="m-auto p-1">Status <i class="fas fa-caret-down" /></span>
                    </template>
                    <b-dropdown-item value="Available" @click="updateUnitStatus('Available')">
                      Available
                      <b-badge :variant="getPersonnelStatus('Available').variant">
                        <i :class="getPersonnelStatus('Available').icon" />
                      </b-badge>
                    </b-dropdown-item>
                    <b-dropdown-item value="On-Scene" @click="updateUnitStatus('On-Scene')">
                      Staged
                      <b-badge :variant="getPersonnelStatus('On-Scene').variant">
                        <i :class="getPersonnelStatus('On-Scene').icon" />
                      </b-badge>
                    </b-dropdown-item>
                    <b-dropdown-item value="Responding" @click="updateUnitStatus('Responding')">
                      Responding
                      <b-badge :variant="getPersonnelStatus('Responding').variant">
                        <i :class="getPersonnelStatus('Responding').icon" />
                      </b-badge>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="col">
                  <b-dropdown
                    v-if="unit?.selected"
                    v-model="selectedStatus"
                    size="sm"
                  >
                    <template v-slot:button-content>
                      <span>Tasks <i class="fas fa-caret-down" /></span>
                    </template>
                    <b-dropdown-item
                      :value="task"
                      v-for="(task, index) in tasks"
                      :key="index"
                    >
                      {{ task.name }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-card-text>
            <b-card-text class="my-auto col-1">
              <b-badge :variant="getPersonnelStatus(unit.status).variant">
                <i :class="getPersonnelStatus(unit.status).icon" />
              </b-badge>
            </b-card-text>
            <div class="mx-2" v-if="filters.togglePersonnel.enable">
              <div
                class="row mx-2 my-1"
                v-for="(personnel, index) in getUnitPersonnel(unit)"
                :key="index"
              >
                <div class="col-1">
                  <i class="fas fa-user-alt" />
                </div>
                <div class="col">
                  <span>{{ personnel.first_name }} {{ personnel.last_name }}</span>
                </div>
                <div class="col-2">
                  <b-badge :variant="getPersonnelStatus(personnel.status).variant">
                    <i :class="getPersonnelStatus(personnel.status).icon" />
                  </b-badge>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-collapse>
        <!-- Responding -->
        <b-card-header class="p-1">
          <b-button
            class="w-100 p-1 d-flex gap-2 justify-content-start"
            @click="switchCollapse('responding')"
            :variant="expandedResponding ? 'primary' : 'info'"
          >
            <b-badge variant="secondary" class="my-auto">
              {{ getClientRespondingUnits?.length }}
            </b-badge>
            <span class="bold my-auto">Responding</span>
          </b-button>
        </b-card-header>
        <b-collapse v-model="expandedResponding" class="listing">
          <b-card-body
            v-for="(unit, index) in getClientRespondingUnits"
            :key="index"
            class="row border p-1 m-1 row-highlight"
          >
            <b-card-text class="m-0 p-0 custom-width" v-if="unit?.selected">
              <div class="h-100 ml-auto bg-secondary">&nbsp;</div>
            </b-card-text>
            <b-card-text
              class="m-auto col-1 cursor-pointer"
              @click="selectUnit(unit)"
            >
              <i class="fas fa-car-alt m-auto" />
            </b-card-text>
            <b-card-text class="my-auto col">
              <div class="row justify-content-center">
                <!-- Unit name -->
                <div
                  class="my-auto col content-highlight rounded-2 text-center cursor-pointer"
                  @click="selectUnit(unit)"
                  @keydown="selectUnit(unit)"
                >
                  <span class="font-weight-semibold">{{ unit.name }}</span>
                </div>
                <!-- Status and Task selects -->
                <div class="col">
                  <b-dropdown
                    v-if="unit?.selected"
                    v-model="selectedStatus"
                    size="sm"
                  >
                    <template v-slot:button-content>
                      <span class="m-auto p-1">Status <i class="fas fa-caret-down" /></span>
                    </template>
                    <b-dropdown-item value="Available" @click="updateUnitStatus('Available')">
                      Available
                      <b-badge :variant="getPersonnelStatus('Available').variant">
                        <i :class="getPersonnelStatus('Available').icon" />
                      </b-badge>
                    </b-dropdown-item>
                    <b-dropdown-item value="On-Scene" @click="updateUnitStatus('On-Scene')">
                      On Scene
                      <b-badge :variant="getPersonnelStatus('On-Scene').variant">
                        <i :class="getPersonnelStatus('On-Scene').icon" />
                      </b-badge>
                    </b-dropdown-item>
                    <b-dropdown-item value="Responding" @click="updateUnitStatus('Responding')">
                      Responding
                      <b-badge :variant="getPersonnelStatus('Responding').variant">
                        <i :class="getPersonnelStatus('Responding').icon" />
                      </b-badge>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="col">
                  <b-dropdown
                    v-if="unit?.selected"
                    v-model="selectedStatus"
                    size="sm"
                  >
                    <template v-slot:button-content>
                      <span>Tasks <i class="fas fa-caret-down" /></span>
                    </template>
                    <b-dropdown-item
                      :value="task"
                      v-for="(task, index) in tasks"
                      :key="index"
                    >
                      {{ task.name }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-card-text>
            <b-card-text class="my-auto col-1">
              <b-badge :variant="getPersonnelStatus(unit.status).variant">
                <i :class="getPersonnelStatus(unit.status).icon" />
              </b-badge>
            </b-card-text>
            <div class="mx-2" v-if="filters.togglePersonnel.enable">
              <div
                class="row mx-2 my-1"
                v-for="(personnel, index) in getUnitPersonnel(unit)"
                :key="index"
              >
                <div class="col-1">
                  <i class="fas fa-user-alt" />
                </div>
                <div class="col">
                  <span>{{ personnel.first_name }} {{ personnel.last_name }}</span>
                </div>
                <div class="col-2">
                  <b-badge :variant="getPersonnelStatus(personnel.status).variant">
                    <i :class="getPersonnelStatus(personnel.status).icon" />
                  </b-badge>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-collapse>
        <!-- Available -->
        <b-card-header class="p-1">
          <b-button
            class="w-100 p-1 d-flex gap-2 justify-content-start"
            @click="switchCollapse('available')"
            :variant="expandedAvailable ? 'primary' : 'info'"
          >
            <b-badge variant="secondary" class="my-auto">
              {{ getClientAvailableUnits?.length }}
            </b-badge>
            <span class="bold my-auto">Available</span>
          </b-button>
        </b-card-header>
        <b-collapse v-model="expandedAvailable" class="listing">
          <b-card-body
            v-for="(unit, index) in getClientAvailableUnits"
            :key="index"
            class="row border p-1 m-1 row-highlight"
          >
            <b-card-text class="m-0 p-0 custom-width" v-if="unit?.selected">
              <div class="h-100 ml-auto bg-secondary">&nbsp;</div>
            </b-card-text>
            <b-card-text
              class="m-auto col-1 cursor-pointer"
              @click="selectUnit(unit)"
            >
              <i class="fas fa-car-alt m-auto" />
            </b-card-text>
            <b-card-text class="my-auto col">
              <div class="row justify-content-center">
                <!-- Unit name -->
                <div
                  class="my-auto col content-highlight rounded-2 text-center cursor-pointer"
                  @click="selectUnit(unit)"
                  @keydown="selectUnit(unit)"
                >
                  <span class="font-weight-semibold">{{ unit.name }}</span>
                </div>
                <!-- Status and Task selects -->
                <div class="col">
                  <b-dropdown
                    v-if="unit?.selected"
                    v-model="selectedStatus"
                    size="sm"
                  >
                    <template v-slot:button-content>
                      <span class="m-auto p-1">Status <i class="fas fa-caret-down" /></span>
                    </template>
                    <b-dropdown-item value="Available" @click="updateUnitStatus('Available')">
                      Available
                      <b-badge :variant="getPersonnelStatus('Available').variant">
                        <i :class="getPersonnelStatus('Available').icon" />
                      </b-badge>
                    </b-dropdown-item>
                    <b-dropdown-item value="On-Scene" @click="updateUnitStatus('On-Scene')">
                      Staged
                      <b-badge :variant="getPersonnelStatus('On-Scene').variant">
                        <i :class="getPersonnelStatus('On-Scene').icon" />
                      </b-badge>
                    </b-dropdown-item>
                    <b-dropdown-item value="Responding" @click="updateUnitStatus('Responding')">
                      Responding
                      <b-badge :variant="getPersonnelStatus('Responding').variant">
                        <i :class="getPersonnelStatus('Responding').icon" />
                      </b-badge>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="col">
                  <b-dropdown
                    v-if="unit?.selected"
                    v-model="selectedStatus"
                    size="sm"
                  >
                    <template v-slot:button-content>
                      <span>Tasks <i class="fas fa-caret-down" /></span>
                    </template>
                    <b-dropdown-item
                      :value="task"
                      v-for="(task, index) in tasks"
                      :key="index"
                    >
                      {{ task.name }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-card-text>
            <b-card-text class="my-auto col-1">
              <b-badge :variant="getPersonnelStatus(unit.status).variant">
                <i :class="getPersonnelStatus(unit.status).icon" />
              </b-badge>
            </b-card-text>
            <div class="mx-2" v-if="filters.togglePersonnel.enable">
              <div
                class="row mx-2 my-1"
                v-for="(personnel, index) in getUnitPersonnel(unit)"
                :key="index"
              >
                <div class="col-1">
                  <i class="fas fa-user-alt" />
                </div>
                <div class="col">
                  <span>{{ personnel.first_name }} {{ personnel.last_name }}</span>
                </div>
                <div class="col-2">
                  <b-badge :variant="getPersonnelStatus(personnel.status).variant">
                    <i :class="getPersonnelStatus(personnel.status).icon" />
                  </b-badge>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  personnelComputed, personnelMethods,
  authComputed, unitsComputed, unitsMethods,
  incidentsComputed,
} from '@/state/helpers';
import Button from '@/components/widgets/button.vue';

export default {
  components: {
    Button,
  },
  props: ['active'],
  emits: ['centerResource'],
  data() {
    return {
      selectedStatus: 'Available',
      selectedTask: '00001',
      tasks: [
        { value: '00001', text: 'Task 01' },
        { value: '00002', text: 'Task 02' },
      ],
      expandedStagged: false,
      expandedResponding: false,
      expandedAvailable: false,
      filters: {
        togglePersonnel: {
          name: 'Toggle Personnel',
          description: 'Enable/Disable Personnel visibility.',
          icon: 'fas fa-people-group',
          enable: false,
        },
        showShared: {
          name: 'Accounts Restriction',
          description: 'Enable/Disable Showing all accounts connected or only yours resources.',
          icon: 'fas fa-earth-americas',
          enable: false,
        },
        showGPS: {
          name: 'Show GPS',
          description: 'Enable/Disable Listing only resources with location available.',
          icon: 'fas fa-map-location',
          enable: false,
        },
      },
      search: '',
      fields: [
        {
          key: 'status',
          label: 'Status',
          thClass: 'mx-auto',
        },
        {
          key: 'first_name',
          label: 'First Name',
        },
        {
          key: 'last_name',
          label: 'Last Name',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
    };
  },
  computed: {
    ...personnelComputed,
    ...authComputed,
    ...unitsComputed,
    ...incidentsComputed,
  },
  watch: {
    search(data) {
      this.updateSearch({ text: data });
    },
    active(newValue) {
      this.show = newValue;
    },
  },
  methods: {
    ...personnelMethods,
    ...unitsMethods,
    switchCollapse(collapse) {
      switch (collapse) {
        case 'staged': this.expandedStagged = !this.expandedStagged;
          this.expandedAvailable = false;
          this.expandedResponding = false;
          break;
        case 'responding': this.expandedStagged = false;
          this.expandedAvailable = false;
          this.expandedResponding = !this.expandedResponding;
          break;
        case 'available': this.expandedStagged = false;
          this.expandedAvailable = !this.expandedAvailable;
          this.expandedResponding = false;
          break;
        default: break;
      }
    },
    async updateUnitStatus(status) {
      const update = await this.updateUnitsStatus({
        status,
        user: JSON.parse(this.currentUser),
        incident: this.getCurrentIncident,
      });
      if (update) {
        this.clearUnitsSelection({});
      }
    },
    getUnitPersonnel(unit) {
      return this.getClientPersonnel.filter(
        (item) => item.unit_id === unit.id,
      );
    },
    switchFilter(filter) {
      filter.enable = !filter.enable;
      this.applyFilters();
    },
    async applyFilters() {
      if (this.filters.togglePersonnel.enable && !this.getClientPersonnel?.length) {
        await this.loadClientPersonnel({
          user: JSON.parse(this.currentUser),
          loadFull: true,
          invalidate: true,
        });
      }

      this.setUnitFilter({
        showGPS: this.filters.showGPS.enable,
        connectedAccounts: this.filters.showShared.enable,
      });
    },
    selectUnit(unit) {
      if (this.filters.showGPS.enable) {
        const { address, latitude, longitude } = unit.coordinates;

        if (address || (latitude && longitude)) {
          this.$emit('centerResource', { latitude, longitude, address });
        }
      }

      this.setSelectedUnit({ unit });
    },
    getPersonnelStatus(status) {
      let variant;
      let icon;

      switch (status) {
        case 'Responding':
          variant = 'warning';
          icon = 'fas fa-arrow-right';
          break;
        case 'On-Scene':
          variant = 'info';
          icon = 'fas fa-check';
          break;
        default:
          variant = 'success';
          icon = 'fas fa-arrow-up-from-bracket';
          break;
      }

      return { variant, icon };
    },
  },
  mounted() {
    this.applyFilters();

    if (
      !this.getClientPersonnel?.length
    ) {
      this.loadClientPersonnel({
        user: JSON.parse(this.currentUser),
        loadFull: true,
        invalidate: true,
      });
    }
    if (!this.getClientUnits?.length) {
      this.loadClientUnits({
        user: JSON.parse(this.currentUser),
        loadFull: true,
        invalidate: true,
      });
    }
  },
};

</script>
<style>
.cursor-pointer {
  cursor: pointer;
}
.cursor-crosshair {
  cursor: crosshair;
}
.cursor-grab {
  cursor: grab;
}
.listing {
  min-height: 179px;
  max-height: 180px;
  overflow-y: auto;
}
.content-highlight {
  background-color: #efefef;
  color: #888888;
}
.content-highlight:hover {
  color: #ffffff;
  background-color: #b9b9b9;
}
.row-highlight {
  background-color: #ffffff;
}
.row-highlight:hover {
  background-color: #cdcdcd;
}
.custom-width {
  width: 5px;
}
</style>
