<template>
  <b-collapse visible>
      <div class="d-flex flex-column gap-2">
        <div class="w-100 d-flex justify-content-between mt-2">
          <h5 class="my-auto mx-auto text-center">Settings</h5>
          <b-button variant="danger" size="sm" class="my-auto mr-3" @click="cancelEditing">
            <i class="fas fa-xmark" />
          </b-button>
        </div>
        <div class="row">
          <!-- Layer selection -->
          <div class="col">
            <b-form-floating-label>
              <span class="col-8 text-start px-2">Name</span>
            </b-form-floating-label>
            <b-form-input
              v-model="form.name"
              placeholder="Name"
              :class="{'is-invalid': v$.form.name.$error}"
            />
            <div
              v-for="(item, index) in v$.form.name.$errors"
              :key="index"
              class="invalid-feedback"
            >
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>
          <div class="col">
            <b-form-floating-label>
              <span class="col-8 text-start px-2">Layer</span>
            </b-form-floating-label>
            <b-form-select v-model="form.layer_id">
              <b-form-select-option
                v-for="(layer, index) in getLayers"
                :key="index"
                :value="layer.id"
              >{{
                  layer.name
                }}
              </b-form-select-option>
            </b-form-select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-form-floating-label class="text-center">
              <span>Notes</span>
            </b-form-floating-label>
            <b-form-textarea v-model="form.notes" class="w-100 mx-auto"/>
          </div>
        </div>
        <div class="row" v-if="latitudeRequired">
          <div class="col">
            <b-form-floating-label>
              <span class="col-8 text-start px-2">Latitude</span>
            </b-form-floating-label>
            <b-form-input
              type="text"
              v-model="form.lat"
              :class="{'is-invalid': v$.form.lat.$error}"
            />
            <div
              v-for="(item, index) in v$.form.lat.$errors"
              :key="index"
              class="invalid-feedback"
            >
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>
          <div class="col">
            <b-form-floating-label>
              <span class="col-8 text-start px-2">Longitude</span>
            </b-form-floating-label>
            <b-form-input
              type="text"
              v-model="form.lng"
              :class="{'is-invalid': v$.form.lng.$error}"
            />
            <div
              v-for="(item, index) in v$.form.lng.$errors"
              :key="index"
              class="invalid-feedback"
            >
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>
        </div>
        <div class="row" v-if="lineRequired">
          <div class="col">
            <b-form-floating-label>
              <span class="col-8 text-start px-2">Line Types </span>
            </b-form-floating-label>
            <b-form-select
              disabled
              v-model="form.line_type"
              :options="lineTypes"
              :class="{'is-invalid': v$.form.line_type.$error}"
            />
            <div
              v-for="(item, index) in v$.form.line_type.$errors"
              :key="index"
              class="invalid-feedback"
            >
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>
          <div class="col">
            <b-form-floating-label>
              <span class="col-8 text-start px-2">Line Thickness </span>
            </b-form-floating-label>
            <b-form-select
              disabled
              v-model="form.line_thickness"
              :options="lineThickness"
              :class="{'is-invalid': v$.form.line_thickness.$error}"
            />
            <div
              v-for="(item, index) in v$.form.line_thickness.$errors"
              :key="index"
              class="invalid-feedback"
            >
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col" v-if="arrowRequired">
            <b-form-floating-label>
              <span class="col-8 text-start px-2">Show arrows </span>
            </b-form-floating-label>
            <b-form-select disabled v-model="form.show_arrows" :options="showArrows"
              :class="{'is-invalid': v$.form.show_arrows.$error}"
            />
            <div
              v-for="(item, index) in v$.form.show_arrows.$errors"
              :key="index"
              class="invalid-feedback"
            >
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col" v-if="lineRequired">
            <b-form-floating-label>
              <div class="row text-start">
                <div class="d-flex flex-row gap-4 my-1">
                  <span class="my-auto">Current Color: </span>
                  <div
                    class="rounded-2"
                    :style="`background-color: ${form.color}; width: 25px; heigth: 25px`">
                    &nbsp;
                  </div>
                </div>
              </div>
            </b-form-floating-label>
            <colorPicker
              :colors-default="[]"
              v-model:color="form.color"
              theme="light"
              @changeColor="changeColor"
              :class="{'is-invalid': v$.form.color.$error}"
            />
            <div
              v-for="(item, index) in v$.form.color.$errors"
              :key="index"
              class="invalid-feedback"
            >
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>
          <div class="col m-2" v-if="radiusRequired">
            <b-form-floating-label>
              <span class="col-8 text-start px-2">Radius</span>
            </b-form-floating-label>
            <div class="row gap-1">
              <b-form-input
                class="col"
                v-model="form.radius"
                :class="{'is-invalid': v$.form.radius.$error}"
              />
              <div
                v-for="(item, index) in v$.form.radius.$errors"
                :key="index"
                class="invalid-feedback"
              >
                <span v-if="item.$message">{{ item.$message }}</span>
              </div>
              <b-form-select disabled v-model="distanceUnit" class="col">
                <b-form-select-option value="feet">Feet</b-form-select-option>
                <b-form-select-option value="miles">Miles</b-form-select-option>
              </b-form-select>
            </div>
          </div>
          <div class="col" v-if="iconRequired">
            <IconSelector
              :resourceRequest="form.type"
              @selectedIcon="updateIcon"
              :class="{'is-invalid': v$.form.icon.$error}"
            />
            <div
              v-for="(item, index) in v$.form.icon.$errors"
              :key="index"
              class="invalid-feedback"
            >
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-2 gap-4">
        <hr class="mt-2" />
        <b-button
          class="col btn-label"
          variant="danger"
          :disabled="!form.id"
          @click="deleteMapTool"
        >
          <i class="bx bx-trash label-icon"></i>Delete
        </b-button>
        <b-button class="col btn-label" variant="danger" @click="cancelEditing">
          <i class="bx bx-block label-icon"></i>Cancel
        </b-button>
        <b-button class="col btn-label" variant="success" @click.prevent="saveMapTool">
          <i class="bx bx-cloud-upload label-icon"></i>Save
        </b-button>
      </div>
  </b-collapse>
</template>

<script>
import colorPicker from '@caohenghu/vue-colorpicker';
import IconSelector from '@/components/commandBoard/selectors/iconSelector.vue';
import {
  layersComputed, incidentsComputed,
  authComputed, mapToolsMethods, mapToolsComputed,
  landmarksComputed, landmarksMethods,
} from '@/state/helpers';
import {
  required, helpers, requiredIf,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { showMessage } from '@/components/widgets/swalUtils';

const initialFormData = () => ({
  id: '',
  name: '',
  icon: 'G1.png',
  layer_id: null,
  notes: '',
  line_type: '0',
  line_thickness: 2,
  color: '#FFFFFF',
  show_arrows: 'no',
  radius: 0,
});

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    colorPicker,
    IconSelector,
  },
  data() {
    return {
      showArrows: [
        {
          html: 'Don\'t show arrow',
          value: 'no',
        },
        {
          html: 'Forward',
          value: 'forward',
        },
        {
          html: 'Forwards',
          value: 'forwards',
        },
        {
          html: 'Reverse',
          value: 'reverse',
        },
        {
          html: 'Reverses',
          value: 'reverses',
        },
        {
          html: 'Perpendicular',
          value: 'perpendicular',
        },
        {
          html: 'Perpreverse',
          value: 'perpreverse',
        },
      ],
      lineTypes: [
        {
          html: '────',
          value: '0',
        },
        {
          html: '----',
          value: '5 2',
        }],
      lineThickness: [
        {
          html: '──── (2px)',
          value: 2,
        },
        {
          html: '▄▄▄▄ (4px)',
          value: 4,
        },
        {
          html: '████ (6px)',
          value: 6,
        },
      ],
      form: initialFormData(),
      mapTool: {},
      distanceUnit: 'miles',
    };
  },
  computed: {
    ...authComputed,
    ...layersComputed,
    ...incidentsComputed,
    ...mapToolsComputed,
    ...landmarksComputed,
    radiusRequired() {
      return this.form.type === 'circle';
    },
    arrowRequired() {
      return this.form.type === 'polyline'
      || this.form.type === 'polygon';
    },
    lineRequired() {
      return this.form.type === 'polyline'
        || this.form.type === 'polygon'
        || this.form.type === 'rectangle'
        || this.form.type === 'circle';
    },
    iconRequired() {
      return this.form.type === 'marker'
        || this.form.type === 'landmark';
    },
    latitudeRequired() {
      return this.form.type === 'circle'
        || this.form.type === 'marker'
        || this.form.type === 'landmark';
    },
    getRadiusMetric() {
      if (this.mapTool?.layer instanceof Object) {
        return (this.mapTool.layer.getRadius() * 0.000621).toFixed(4);
      }
      if (this.mapTool?.sourceTarget) {
        return (this.mapTool.sourceTarget.getRadius() * 0.000621).toFixed(4);
      }
      return 0;
    },
    getRadius() {
      if (this.radiusRequired) {
        if (this.mapTool?.layer instanceof Object) {
          return this.mapTool.layer.getRadius();
        }
        return this.mapTool.sourceTarget.getRadius();
      }
      return 0;
    },
  },
  watch: {
    form: {
      async handler(updated) {
        const { type } = updated;
        if (type === 'circle') {
          // Miles to meter
          this.updateMapToolsRadius(
            {
              radius: (updated.radius * 1609),
            },
          );
        }
      },
      deep: true,
    },
    getEditingMapTools(tool) {
      Object.assign(this.mapTool, { ...tool });
      this.updateEditingMapToolsData();
    },
  },
  methods: {
    ...mapToolsMethods,
    ...landmarksMethods,
    updateEditingMapToolsData() {
      const data = this.getEditingMapToolsData;

      if (data) {
        let radius;
        let location = {};
        switch (data.type) {
          case 'circle':
            // Meters to miles
            radius = this.getRadiusMetric;
            if (this.mapTool?.layer) {
              try {
                location = this.mapTool.layer.getLatLngs();
              } catch (err) {
                const { _latlng } = this.mapTool.layer;
                location.lat = _latlng.lat;
                location.lng = _latlng.lng;
              }
            } else {
              try {
                location = this.mapTool.sourceTarget.getLatLngs();
              } catch (err) {
                const { _latlng } = this.mapTool.sourceTarget;
                location.lat = _latlng.lat;
                location.lng = _latlng.lng;
              }
            }
            Object.assign(this.form, { ...data, radius, ...location });
            break;
          default: Object.assign(this.form, { ...data });
            break;
        }
      }
    },
    updateIcon(icon) {
      if (this.form.type === 'marker') {
        this.form.icon = `/img/maptools/${icon}`;
      } else {
        this.form.icon = `/img/landmarks/${icon}`;
      }
      // TODO: Update marker icon realtime
    },
    async deleteMapTool() {
      if (this.form.type === 'landmark') {
        await this.deleteLandmark({
          form: this.form,
          user: JSON.parse(this.currentUser),
        });
        this.$emit('landmarkUpdated');
        this.form = initialFormData();
      }

      await this.deleteMapTools({
        mapTool: this.form,
        incident: this.getCurrentIncident,
        user: JSON.parse(this.currentUser),
      });
      this.$emit('mapToolsUpdated');
      this.form = initialFormData();
    },
    async cancelEditing() {
      this.$emit('mapToolsUpdated');
      this.$emit('landmarkUpdated');
      this.form = initialFormData();
    },
    async saveMapTool() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return showMessage({
          title: 'Invalid data provided',
          text: 'Check the highlighted error.',
          icon: 'error',
        });
      }
      if (this.form.type === 'landmark') {
        if (!this.form.layer_id) {
          return showMessage({
            title: 'Invalid',
            text: 'A landmark must have one layer.',
            icon: 'error',
          });
        }
        const created = await this.createLandmark({
          form: this.form,
          user: JSON.parse(this.currentUser),
          tool: this.mapTool,
        });
        if (created) {
          this.form = initialFormData();
          return this.$emit('landmarkUpdated');
        }
        return false;
      }

      await this.createMapTool({
        form: this.form,
        user: JSON.parse(this.currentUser),
        incident: this.getCurrentIncident,
      });
      this.form = initialFormData();
      return this.$emit('mapToolsUpdated');
    },
    changeColor(color) {
      this.form.color = color.hex;
    },
  },
  validations: {
    form: {
      name: {
        required: helpers.withMessage(
          'The name of the mapTools is required',
          required,
        ),
      },
      icon: {
        requiredIfIcon: requiredIf(() => {
          if (this?.iconRequired) {
            return this.iconRequired;
          }
          return false;
        }),
      },
      notes: {},
      line_type: {
        requiredIfLine: requiredIf(() => {
          if (this?.lineRequired) {
            return this.lineRequired;
          }
          return false;
        }),
      },
      line_thickness: {
        requiredIfLine: requiredIf(() => {
          if (this?.lineRequired) {
            return this.lineRequired;
          }
          return false;
        }),
      },
      color: {
        requiredIfColor: requiredIf(() => {
          if (this?.lineRequired) {
            return this.lineRequired;
          }
          return false;
        }),
      },
      show_arrows: {
        requiredIfArrows: requiredIf(() => {
          if (this?.arrowRequired) {
            return this.arrowRequired;
          }
          return false;
        }),
      },
      lat: {
        requiredIfLatitude: requiredIf(() => {
          if (this?.latitudeRequired) {
            return this.latitudeRequired;
          }
          return false;
        }),
      },
      lng: {
        requiredIfLongitude: requiredIf(() => {
          if (this?.latitudeRequired) {
            return this.latitudeRequired;
          }
          return false;
        }),
      },
      radius: {
        requiredIfRadius: helpers.withMessage('The radius is required for this mapTool.', requiredIf(() => {
          if (this?.radiusRequired) {
            return this.radiusRequired;
          }
          return false;
        })),
      },
      layer_id: {
        requiredIfLandmark: requiredIf(() => this?.form?.type === 'landmark'),
      },
    },
  },
};

</script>
