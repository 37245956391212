<template>
    <div class="row">
      <div class="col-8">
        <img alt="Selected Icon"
             class="col-4 m-auto"
             style="width: 20px"
             :src="require(`@/assets/images/${getFormattedAssetUrl(mapIcon)}`)"
        />
        <b-dropdown dropleft variant="info" class="col-4 m-2">
          <template v-slot:button-content>
            <span class="mx-auto">
              Icon <i class="fas fa-caret-down" />
            </span>
          </template>
          <div class="icons overflow-auto m-2 gap-2">
            <div
              v-for="
              (iconsGroup, category) in
               this.listIcons
              "
              :key="category">
              <span class="m-2 bold">{{ category.toUpperCase() }}</span>
              <hr>
              <button v-for="icon in iconsGroup" :key="icon"
                      @click="selectIcon(icon)"
                      class="rounded m-1">
                <img
                  :src="require(`@/assets/images/${getFormattedAssetUrl(icon)}`)"
                  class="m-1 p-1"
                  alt="Icon"
                />
              </button>
              <hr />
            </div>
          </div>
        </b-dropdown>
      </div>
    </div>
</template>
<script>

import { mapToolsComputed } from '@/state/helpers';
import { maptoolsIcons, landmarksIcons, tasksIcons } from '@/components/iconsDict';

export default {
  data() {
    return {
      mapIcon: 'G1.png',
      listIcons: null,
      maptoolsIcons,
      landmarksIcons,
      tasksIcons,
    };
  },
  props: {
    resourceRequest: {
      type: String,
      default: 'marker',
      validator: (value) => ['marker', 'landmark', 'task'].includes(value),
    },
  },
  computed: {
    ...mapToolsComputed,
  },
  methods: {
    selectIcon(icon) {
      this.mapIcon = icon;
      this.$emit('selectedIcon', icon);
    },
    getFormattedAssetUrl(url) {
      if (this.getEditingMapToolsData?.type === 'landmark') {
        const lookup = /\/img\/landmarks/g;
        if (url.match(lookup)) {
          return url.toString().replace('/img/landmarks', 'landmarks');
        }
        return `landmarks/${url}`;
      }

      if (this.getEditingMapToolsData?.type === 'marker') {
        const lookup = /\/img\/maptools/g;
        if (url.match(lookup)) {
          return url.toString().replace('/img/maptools', 'maptools');
        }
        return `maptools/${url}`;
      }

      const lookup = /\/img\/task/g;
      if (url.match(lookup)) {
        return url.toString().replace('/img/task', 'task');
      }
      return `task/${url}`;
    },
  },
  mounted() {
    switch (this.resourceRequest) {
      case 'marker': this.listIcons = this.maptoolsIcons;
        if (this.getEditingMapToolsData?.icon) {
          this.mapIcon = this.getEditingMapToolsData.icon;
        }
        break;
      case 'landmarks': this.listIcons = this.landmarksIcons;
        if (this.getEditingMapToolsData?.icon) {
          this.mapIcon = this.getEditingMapToolsData.icon;
        }
        break;
      case 'task': this.listIcons = this.tasksIcons;
        break;
      default: break;
    }
  },
};

</script>
<style scoped>
.icons {
  width: 400px;
  height: 400px;
}
</style>
